import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  notification_api,
  update_notification_api,
} from '../../../service/misc'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

export default function Notification() {
  const { data, refetch } = useQuery({
    queryKey: ['notifications', `filter={"read_status":false}&page_size=10`],
    queryFn: notification_api,
  })
  const mutation = useMutation({
    mutationFn: update_notification_api,
    onSuccess: () => {
      refetch()
    },
  })
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <MenuButton className='inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-50 px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100'>
          <svg
            className='flex-shrink-0 size-4'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <path d='M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9' />
            <path d='M10.3 21a1.94 1.94 0 0 0 3.4 0' />
          </svg>
          <div className='absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900'>
            {data?.count}
          </div>
        </MenuButton>
      </div>

      <MenuItems
        transition
        className='absolute -right-10 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'
      >
        <div className='py-1'>
          <MenuItem>
            <b className='block px-4 py-2 text-sm text-gray-700'>
              Notifications
            </b>
          </MenuItem>
        </div>
        {data?.rows?.map((entity, index) => (
          <div className='py-1' key={index}>
            <MenuItem>
              <Link
                to={'/schedules?id=' + entity.extra.schedule_id}
                className='block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none'
                onClick={() =>
                  mutation.mutate({ id: entity.id, read_status: true })
                }
              >
                <i>
                  <small>{dayjs(entity.created_at).format('DD/MM/YY')}</small>
                </i>{' '}
                - {entity.title || entity.model + ' ' + entity.operation + 'd'}
              </Link>
            </MenuItem>
          </div>
        ))}
        {!data?.rows?.length && (
          <MenuItem>
            <div className='block px-4 py-2 text-sm text-gray-700'>
              No new notifications
            </div>
          </MenuItem>
        )}
      </MenuItems>
    </Menu>
  )
}
