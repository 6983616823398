import {
  faArrowLeft,
  faBars,
  faCalendarDays,
  faCalendarXmark,
  faCheckCircle,
  faChevronRight,
  faClipboard,
  faFontAwesome,
  faGear,
  faLocationDot,
  faMoneyBill,
  faNotesMedical,
  faQuestion,
  faUserPen,
  faUsers,
  faVolumeUp
} from '@fortawesome/free-solid-svg-icons'
import React, { Suspense, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import SidebarItem from './component/SidebarItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fileSrc } from '../../component/FileLoader'
import { useMutation, useQuery } from '@tanstack/react-query'
import { settings_api, update_setting_api } from '../../service/settings'
import Notification from './component/Notification'
import UserProfile from './component/UserProfile'
import { Button2 } from '../../component/Button'
import { useAuth } from '../../context/AuthContext'

export default function WebLayout() {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const [isMenuOpen, setMenuOpen] = useState(false)
  const { data: settings, refetch } = useQuery({
    queryKey: ['settings'],
    queryFn: settings_api
  })
  const updateSettingsMutation = useMutation({
    mutationFn: update_setting_api,
    onSuccess: () => {
      refetch()
    }
  })
  const guided_setup = [
    {
      name: 'settings',
      title: "Review your company's profile & settings",
      link: '/settings'
    },
    {
      name: 'roles',
      title:
        "Manage your company's organizational structure (Roles & Permissions)",
      link: '/users/roles'
    },
    {
      name: 'staffs',
      title: 'Invite your staffs to join the company',
      link: '/users'
    },
    {
      name: 'clients',
      title: 'Manage your Clients Data',
      link: '/locations'
    },
    {
      name: 'services',
      title: 'Add the services you render',
      link: '/services'
    }
  ]
  const [completed_guided_setup, setCompletedGuidedSetup] = useState(['all'])
  useEffect(() => {
    if (settings?.hasOwnProperty('completed_guided_setup'))
      setCompletedGuidedSetup(
        settings?.completed_guided_setup?.split(',') || []
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.completed_guided_setup])
  const updateGuidedSetup = (data: string) => {
    let completed = new Set(completed_guided_setup)
    completed.add(data)
    if (completed.size === guided_setup.length) completed.add('all')
    updateSettingsMutation.mutate({
      completed_guided_setup: Array.from(completed).join(',')
    })
    navigate(guided_setup.find((g) => g.name === data)?.link || '')
  }

  const [showMobileSideBar, setShowMobileSideBar] = useState(false)

  let styleHearder: any = {
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    fontFamily: 'Inter'
  }
  return (
    <div className='h-screen bg-[#F1F4FB]'>
      <header
        className={`no-print sticky top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-30
				 w-full bg-white border-b text-sm py-2.5 sm:py-4  transition-all duration-300 
				 ${isMenuOpen && 'lg:ps-24 '}
				 ${!isMenuOpen && 'lg:ps-64 '}`}
      >
        <nav
          className='flex basis-full items-center w-full mx-auto px-4 sm:px-6 md:px-8'
          aria-label='Global'
        >
          <div className='me-5 lg:me-0 lg:hidden'>
            <div className='flex-none text-xl font-semibold flex'>
              <button
                onClick={() => setShowMobileSideBar(!showMobileSideBar)}
                type='button'
                className='pr-2 text-gray-500 hover:text-gray-600'
              >
                <span className='sr-only'>Toggle Navigation</span>
                <svg
                  className='flex-shrink-0 size-6'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <line x1='3' x2='21' y1='6' y2='6' />
                  <line x1='3' x2='21' y1='12' y2='12' />
                  <line x1='3' x2='21' y1='18' y2='18' />
                </svg>
              </button>
              <img
                className='sm:h-8 sm:w-8 h-7 w-7 object-contain'
                src={
                  settings?.business_logo ?
                    fileSrc({
                      id: settings?.business_logo,
                      provider: 'cloudinary',
                      format: ''
                    })
                  : 'https://res.cloudinary.com/tobilasinde/image/upload/v1715547435/jd3x68ohm3szqdm0uodr.jpg'
                }
                alt=''
              />
              {/* <p style={styleHearder} className='px-2 text-sm'>
									{settings?.business_name}
								</p> */}
            </div>
          </div>

          <div className='w-full flex items-center  justify-end ms-auto sm:justify-between sm:gap-x-3 sm:order-3'>
            {/* <div className='sm:hidden'>
								<button
									type='button'
									className='w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none '
								>
									<svg
										className='flex-shrink-0 size-4'
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									>
										<circle cx='11' cy='11' r='8' />
										<path d='m21 21-4.3-4.3' />
									</svg>
								</button>
							</div> */}

            <div className='flex item-center '>
              <button
                className='mr-3 invisible lg:visible'
                onClick={() => setMenuOpen(!isMenuOpen)}
              >
                {!isMenuOpen && <FontAwesomeIcon icon={faBars} />}
                {isMenuOpen && <FontAwesomeIcon icon={faArrowLeft} />}
              </button>
              <p
                style={styleHearder}
                className='px-2 py-auto text-sm uppercase'
              >
                {settings?.business_name}
              </p>
            </div>
            {/* <div className='hidden sm:block'>
								<div>
									<label
										htmlFor='hs-trailing-button-add-on-with-icon'
										className='sr-only'
									>
										Search
									</label>
									<div className='flex itme-center '>
										<div className='flex rounded-lg shadow-sm '>
											<input
												type='text'
												id='hs-search-box-with-loading-4'
												name='hs-search-box-with-loading-4'
												className='py-3 px-4 block w-full border-none shadow-sm rounded-s-lg text-sm focus:z-10  0 disabled:opacity-50 disabled:pointer-events-none '
												placeholder=''
											/>
											<button
												type='button'
												className='py-3 px-4 inline-flex justify-center text-white items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent bg-[#EC7211] text-white disabled:opacity-50 disabled:pointer-events-none '
											>
												<svg
													className='w-5 h-5 text-white'
													aria-hidden='true'
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 20 20'
												>
													<path
														stroke='currentColor'
														strokeLinecap='round'
														strokeLinejoin='round'
														strokeWidth='2'
														d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
													/>
												</svg>
												Search
											</button>
										</div>
									</div>
								</div>
							</div> */}

            <div className='flex flex-row items-center justify-end gap-2'>
              <Notification />
              <UserProfile />
            </div>
          </div>
        </nav>
      </header>
      {/* <div className='sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden '>
					<div className='flex items-center py-4'>
						<button
							onClick={() => setShowMobileSideBar(!showMobileSideBar)}
							type='button'
							className='text-gray-500 hover:text-gray-600'
						>
							<span className='sr-only'>Toggle Navigation</span>
							<svg
								className='flex-shrink-0 size-4'
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							>
								<line x1='3' x2='21' y1='6' y2='6' />
								<line x1='3' x2='21' y1='12' y2='12' />
								<line x1='3' x2='21' y1='18' y2='18' />
							</svg>
						</button>
					</div>
				</div> */}

      {showMobileSideBar && (
        <button
          onClick={() => setShowMobileSideBar(!showMobileSideBar)}
          className='text-gray-400 hover:text-white absolute bg-black z-30 opacity-5 h-screen w-full top-0 m-1 right-0'
        >
          {/* &times; */}
        </button>
      )}

      <div
        id='application-sidebar'
        className={`no-print 
					 transition-all duration-300
					 transform  fixed lg:top-0 lg:start-0 lg:bottom-0 ${!isMenuOpen ? 'lg:w-64' : 'lg:w-28'} 
					 bg-white border-e border-gray-200 fixed z-40 
					 pt-7 pb-10 ${!isMenuOpen ? 'overflow-hidden ' : ''} 
					 block lg:end-auto lg:bottom-0 lg:translate-x-0 ${showMobileSideBar ? 'translate-x-0' : '-translate-x-full'}
					 `}
      >
        <div className='px-6'>
          <span
            className='flex-none text-xl flex font-semibold '
            aria-label='Brand'
          >
            <img
              className='h-7 object-contain m-auto'
              src={
                settings?.business_logo ?
                  fileSrc({
                    id: settings?.business_logo,
                    provider: 'cloudinary',
                    format: ''
                  })
                : 'https://res.cloudinary.com/tobilasinde/image/upload/v1715547435/jd3x68ohm3szqdm0uodr.jpg'
              }
              alt=''
            />
          </span>
        </div>

        <nav
          className='hs-accordion-group pt-5 pl-5 w-full  flex flex-col flex-wrap'
          data-hs-accordion-always-open
        >
          <ul className='space-y-1.5 '>
            {/* <SidebarItem
								isMenuOpen={isMenuOpen}
								link={'dashboard'}
								id={'dashboard'}
								title={'Dashboard'}
								icon={faHouse}
							/> */}

            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'schedules'}
              id={'schedules'}
              title={'Schedules'}
              icon={faCalendarDays}
              onClick={() => setShowMobileSideBar(false)}
            />

            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'locations'}
              id={'locations'}
              title={'Clients'}
              icon={faLocationDot}
              onClick={() => setShowMobileSideBar(false)}
            />

            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'services'}
              id={'services'}
              title={'Services'}
              icon={faNotesMedical}
              onClick={() => setShowMobileSideBar(false)}
            />

            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'finances'}
              id={'finances'}
              title={'Finance'}
              icon={faMoneyBill}
              dropdownList={[
                { link: 'invoices', title: 'Invoices', id: 'invoices' },
                { link: 'payrolls', title: 'Payrolls', id: 'payrolls' },
                { link: 'expenses', title: 'Expenses', id: 'expenses' }
              ]}
              onClick={() => setShowMobileSideBar(false)}
            />

            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'communications'}
              id={'communications'}
              title={'Chat'}
              icon={faVolumeUp}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'users'}
              id={'users'}
              title={'User Management'}
              icon={faUsers}
              dropdownList={[
                {
                  link: '',
                  title: 'Team Members',
                  id: 'users'
                },
                {
                  link: 'roles',
                  title: 'Roles',
                  id: 'roles'
                }
              ]}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'timeoff'}
              id={'timeoff'}
              title={'Time off'}
              icon={faCalendarXmark}
              dropdownList={[
                {
                  link: 'timeoff-requests',
                  title: 'Time Off Requests',
                  id: 'timeoff-requests'
                },
                {
                  link: 'timeoff-categories',
                  title: 'Time Off Category',
                  id: 'timeoff-categories'
                }
              ]}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'report'}
              id={'report'}
              title={'Report'}
              icon={faClipboard}
              dropdownList={[
                {
                  link: 'timesheets',
                  title: 'Timesheet',
                  id: 'timesheets'
                },
                {
                  link: 'payroll',
                  title: 'Payroll',
                  id: 'payroll'
                },
                {
                  link: 'invoice',
                  title: 'Invoice',
                  id: 'invoice'
                }
              ]}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'settings'}
              id={'settings'}
              title={'Settings'}
              icon={faGear}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'audit-logs'}
              id={'audit-logs'}
              title={'Audit'}
              icon={faFontAwesome}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'profile'}
              id={'profile'}
              title={'Profile'}
              icon={faUserPen}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'support'}
              id={'support'}
              title={'Support'}
              icon={faQuestion}
              onClick={() => setShowMobileSideBar(false)}
            />
            <SidebarItem
              isMenuOpen={isMenuOpen}
              link={'faq'}
              id={'faq'}
              title={'FAQ'}
              icon={faQuestion}
              onClick={() => setShowMobileSideBar(false)}
            />
          </ul>
        </nav>
      </div>
      <div
        id='c-close'
        className={`w-full h-max pt-4 sm:pt-7 px-4 transition-all duration-300 md:px-8 
				${!isMenuOpen ? 'lg:ps-72' : 'lg:ps-32'} 
				bg-[#F1F4FB]`}
      >
        <Suspense>
          {process.env.REACT_APP_ADMIN?.includes(currentUser.role?.name) &&
            !completed_guided_setup?.includes('all') && (
              <div className='bg-white rounded p-2 flex flex-col gap-2 h-fit mb-2'>
                <div className=' sm:flex p-1 justify-between'>
                  <p className='text-lg font-medium text-left'>Guided Setup</p>
                  <div className='flex flex-col sm:flex-row sm:items-center gap-2'>
                    <Button2
                      title='Remind Me Later'
                      btnStyle='outline'
                      onClick={() =>
                        setCompletedGuidedSetup([
                          ...completed_guided_setup,
                          'all'
                        ])
                      }
                    />
                    <Button2
                      title='Mark As Completed'
                      onClick={() => updateGuidedSetup('all')}
                    />
                  </div>
                </div>
                <hr />
                {guided_setup.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => updateGuidedSetup(item.name)}
                    className={`flex w-full justify-between p-3  transition-all duration-300
                  ${completed_guided_setup.includes(item.name) && 'text-primary bg-primary bg-opacity-10'}
                  rounded items-center`}
                  >
                    <p>{item.title}</p>
                    <div>
                      {completed_guided_setup.includes(item.name) && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className='text-green-500 mr-2'
                        />
                      )}
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </button>
                ))}
              </div>
            )}
          <Outlet />
        </Suspense>
        <br />
        <br /> <br />
      </div>
    </div>
  )
}
