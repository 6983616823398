import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { primary_bg, secondary_bg } from '../../../themes/themes'
import { useAuth } from '../../../context/AuthContext'

export default function SidebarItem({
  link,
  isMenuOpen,
  title,
  icon,
  id,
  dropdownList,
  onClick,
}: {
  isMenuOpen: boolean
  link: string
  title: string
  icon: any
  id: string
  dropdownList?: any
  onClick?: () => void
}) {
  const [link1, setLink] = useState(link)
  const { currentUser } = useAuth()
  const [data, setData] = useState<any>(dropdownList)
  const show = useMemo(() => {
    const exclude = ['Dashboard', 'Profile', 'Support']
    if (
      exclude.includes(title) ||
      process.env.REACT_APP_ADMIN?.includes(currentUser.role?.name)
    )
      return true
    else if (dropdownList) {
      const res = dropdownList.filter(
        (dropdown: any) =>
          currentUser.permissions.find(
            (item: any) => item.model_name === dropdown.id
          )?.read
      )
      if (res.length > 0) {
        setData(res)
        setLink(link + '/' + res[0].link)
        return true
      }
    } else {
      return currentUser.permissions.find((item: any) => item.model_name === id)
        ?.read
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, currentUser])

  // const [isHidden, setIsHidden] = useState(false)
  // const toggleVisibility = (props: any) => {
  // 	// setIsHidden(!isHidden)
  // }

  return show ? (
    <div className='relative'>
      {/* <NavLink
				onClick={toggleVisibility}
				className={({ isActive, isPending }) => {

					return isPending
						? 'flex flex-1  p-2 w-56 m-3 text-slate-500  text-sm '
						: isActive
							? ` 
                      transition-transform transform scale-110 duration-300 ease-in-out
                      text-sm flex flex-1  ${isMenuOpen ? 'w-fit' : 'w-full'} 
					  p-2 w-50 mx-3 border-l-4  items-center text-[${primary_bg}] 
                      border-[${primary_bg}] bg-[${secondary_bg}] `
							: `text-sm flex flex-1 text-slate-500  items-center p-2 w-56 m-3 `
				}}
				to={link1}
			>

			</NavLink> */}

      <NavLink to={link1} onClick={data ? () => {} : onClick}>
        {({ isActive, isPending, isTransitioning }) => (
          <>
            <div
              className={
                isPending
                  ? 'flex flex-1  p-2 w-56 m-3 text-slate-500  text-sm transition-transform transform scale-110 duration-300 ease-in-out'
                  : isActive
                  ? ` 
                      transition-transform transform scale-110 duration-300 ease-in-out
                      text-sm flex flex-1  ${isMenuOpen ? 'w-fit' : 'w-full'} 
					  p-2 w-50 mx-3 border-l-4  items-center text-[${primary_bg}] 
                      border-[${primary_bg}] bg-[${secondary_bg}] `
                  : `text-sm flex flex-1 text-slate-500  items-center p-2 w-56 m-3 `
              }
            >
              <FontAwesomeIcon icon={icon} className='m-1' />

              {!isMenuOpen && <p className='px-2 '>{title}</p>}
              {dropdownList && (
                <button>
                  {!isMenuOpen && (
                    <>
                      {' '}
                      <FontAwesomeIcon
                        // onClick={toggleVisibility}
                        icon={faCaretDown}
                      />{' '}
                    </>
                  )}
                </button>
              )}
            </div>

            {isActive && (
              <ul
                className={
                  isMenuOpen
                    ? 'absolute top-full left-0 -mt-2 w-full bg-white ml-20  rounded-md shadow-lg z-20 mr-10  pl-2 transition-transform transform scale-110 duration-300 ease-in-out'
                    : `list-disc ml-10 list-inside text-gray-800 text-sm`
                }
              >
                {data?.map((item: any) => (
                  <NavLink
                    key={item.id}
                    id={item.id}
                    className={({ isActive, isPending }) =>
                      isPending ? ' ' : isActive ? ` text-[${primary_bg}] ` : ''
                    }
                    to={link + '/' + item.link}
                    onClick={onClick}
                  >
                    <li key={item.id} className='p-3'>
                      {' '}
                      {item.title}{' '}
                    </li>
                  </NavLink>
                ))}
              </ul>
            )}
          </>
        )}
      </NavLink>
    </div>
  ) : null
}
